import React, { useState, useEffect } from 'react';
import { useNavigation, useRoute } from '@react-navigation/native';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import useDataStore from '../stores/dataStore';
import { EVENT_ACTIONS, USER_TYPE } from '../constants/Constant';
import MixpanelTracker from '../utils/MixpanelTracker';

const TransactionList = ({ comment, name, date, time, amount, user, type, userId, transactionId, allowedRole, siteName, siteID, clientID, accountType, commentFactoryExpense, isDisable = false, pendingAmountRoute, listType, plantName, data, transactionHistoryComment }) => {

    const navigation = useNavigation();
    const [userData] = useDataStore((state) => [state.userData]);

    const route = useRoute();
    const currentScreen = route.name;
    const [amountColor, setAmountColor] = useState('red');

    useEffect(() => {
        if (type === USER_TYPE.CLIENTS || user === 'Client' || type === "Other Income" || user === "Other Income" || currentScreen === 'Other Income') {
            setAmountColor('green');
        }
    }, [type]);

    const goToOtherScreen = () => {
        if (currentScreen === 'FactoryExpenses') {
            navigation.navigate('AddFactoryExpense', { existingAmount: amount, existingComment: comment, accountType, commentFactoryExpense, transactionId });
        }
        else if (currentScreen === 'OtherIncome') {
            navigation.navigate('AddOtherIncome', { existingAmount: amount, existingComment: comment, accountType, commentFactoryExpense, transactionId });
        }
        else if (currentScreen !== 'ReportScreen' && (allowedRole ? allowedRole.includes(userData.role) : true)) {
            if (type === 'extraExpense') {
                navigation.navigate('AddExtraExpenseScreen', { userID: userId, existingAmount: amount, existingComment: comment, transactionId });
            } else if (type === 'loadingData') {
                MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.LOADING_DATA_EDIT_CLICKED, userData);
                navigation.navigate('LoadingScreen', { plant: plantName, data, transactionId });
            } else if (type === 'unloadingData') {
                MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.UNLOADING_DATA_EDIT_CLICKED, userData)
                navigation.navigate('UnloadingScreen', { plant: plantName, data, transactionId });
            } else {
                if (type === "ProductionExpense") {
                    MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.PRODUCTION_EDIT_CLICKED, userData)
                    navigation.navigate("AddProductionScreen", { userID: userId, plantName: plantName, data: data });
                } else {
                    navigation.navigate('AddTransactionScreen', { userID: userId, type, existingAmount: amount, transactionId, siteName, siteID, clientID, existingComment: comment, pendingAmountRoute, plantName });
                    if (type === "Employees") MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.EMPLOYEE_EDIT_TRANSACTION, userData);
                    else if (type === "Suppliers") MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.SUPPLIER_EDIT_TRANSACTION, userData);
                    else if (type === 'Thekedars') MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.PRODUCTION_EXPENSE_EDIT_TRANSACTION, userData);
                }
            }
        }
    };

    return (
        <TouchableOpacity style={styles.listTransact} onPress={goToOtherScreen} disabled={userData.username !== user || isDisable}>
            <View style={styles.container}>
                <View style={styles.leftSection}>
                    <Text style={styles.date}>{comment}</Text>
                    <Text style={styles.time}>{time}</Text>
                </View>
                <View style={styles.middleSection}>
                    <Text style={styles.comment}>{commentFactoryExpense}</Text>
                    <Text style={styles.comment}>{transactionHistoryComment}</Text>
                </View>
                <View style={styles.rightSection}>
                    {type !== "unloadingData" && <Text style={[styles.amount, { color: amountColor }]}>{amount}</Text>}
                    <Text style={styles.user}>{user}</Text>
                    {listType && <Text style={[styles.user, { color: '#000' }]}>{listType.charAt(0).toUpperCase() + listType.slice(1).toLowerCase()}</Text>}
                </View>
            </View>
        </TouchableOpacity>
    )
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 10,
        // borderBottomWidth: 1,
        // borderBottomColor: '#ccc',
        backgroundColor: "#fff",
        margin: 10,
        // shadowColor: 'rgba(0, 0, 0, 1)',
        // shadowOpacity: 0.8,
        elevation: 4,
        // shadowRadius: 15,
        // shadowOffset: { width: 1, height: 13 },
    },
    leftSection: {
        flex: 1,
        alignItems: 'flex-start',
    },
    middleSection: {
        flex: 1,
        alignItems: 'center',
    },
    comment: {
        fontSize: 14,
        color: '#000',
    },
    rightSection: {
        flex: 1,
        alignItems: 'flex-end',
    },
    date: {
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 5,
    },
    time: {
        fontSize: 14,
        color: '#666',
    },
    amount: {
        fontSize: 18,
        fontWeight: 'bold',
        color: 'red',
    },
    user: {
        fontSize: 14,
        fontWeight: 'bold',
        color: '#666',
    },
});

export default TransactionList