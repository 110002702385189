import React, { useEffect, useState, useLayoutEffect } from 'react';
import { View, Text, StyleSheet, TextInput, TouchableOpacity, FlatList } from 'react-native';
import { Colors } from '../constants/theme';
import Button from '../components/Button';
import { APP_ROLE, EVENT_ACTIONS, POLE_SIZES, POLE_SIZES_BOLT } from '../constants/Constant';
import { useNavigation } from '@react-navigation/native';
import Icon from 'react-native-vector-icons/FontAwesome';
import CustomCheckbox from '../components/CustomCheckbox';
import MultiSelect from '../components/MultiSelect';
import useDataStore from '../stores/dataStore';
import { makeApiRequest } from '../utils/apiUtil';
import { formatDate } from '../utils/dateUtil';
import Loader from '../components/Loader';
import MixpanelTracker from '../utils/MixpanelTracker';
import { FontAwesome5 } from '@expo/vector-icons';
import DateTimePicker from 'react-native-ui-datepicker';
import Popup from "../components/popup";

const AddProductionScreen = ({ route }) => {
    const { userID, plantName, data } = route.params;
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const [line, setLine] = useState(data?.line || null);
    const [priceLine, setPriceLine] = useState(data?.priceLine || null);
    const [poleData, setPoleData] = useState(data?.poleData || [{ poleQuantity: '', poleSize: '', isBolt: false }]);
    const [cementQty, setCementQty] = useState(data?.cementQty || null);
    const [panelPieces, setPanelPieces] = useState(null);
    const [comment, setComment] = useState(data?.comment || null);
    const [poleLine, setPoleLine] = useState(data?.poleLine || null);
    const [pricePoleLine, setPricePoleLine] = useState(data?.pricePoleLine || null);
    const [poleAmount, setPoleAmount] = useState(data?.poleLine ? parseFloat(data?.poleLine) * parseFloat(data?.pricePoleLine) : 0);

    const [error, setError] = useState('');
    const [cementStock, setCementStock] = useState(0);
    const [panelSize, setPanelSize] = useState(null);
    const navigation = useNavigation();
    const [API_CONFIG, userData, authToken, setAuthToken, refreshToken, setRefreshToken, setToastMessage] = useDataStore((state) => ([state.API_CONFIG, state.userData, state.authToken, state.setAuthToken, state.refreshToken, state.setRefreshToken, state.setToastMessage]));

    const [date, setDate] = useState(data?.date || null);
    const [isDatePickerVisible, setDatePickerVisibility] = useState(false);

    let today = new Date();

    useLayoutEffect(() => {
        let title = `Add Production`;
        if (data) {
            title = `Edit Production`;
        }
        navigation.setOptions({
            title: title,
        });
    }, [navigation]);

    const fetchStock = async () => {
        const data = await makeApiRequest('get', `${API_CONFIG.PLANT}/stocks/${plantName}/getStock`, authToken, refreshToken, setAuthToken, setRefreshToken);
        setCementStock(data?.cement);
    }

    useEffect(() => {
        fetchStock();
    }, [])

    const getPlantSize = async () => {
        if (plantName) {
            let responseData = await makeApiRequest('get', `${API_CONFIG.PLANT}/${plantName}`, authToken, refreshToken, setAuthToken, setRefreshToken);
            setPanelSize(responseData)
            if (data?.line) setPanelPieces(parseFloat(data?.line) * parseFloat(responseData))
        }
    }

    useEffect(() => {
        getPlantSize();
    }, [plantName]);

    const updatePoleData = (index, key, value) => {
        setPoleData(prevData => {
            const newData = [...prevData];
            newData[index][key] = value;
            return newData;
        });

    }

    const addPoleRow = () => {
        setPoleData(prevData => [...prevData, { poleQuantity: '', poleSize: '', isBolt: false }]);
    }

    const removePoleRow = (index) => {
        setPoleData(prevData => prevData.filter((_, i) => i !== index));
    }

    const validateForm = () => {
        if (line || priceLine) {
            if (!line) {
                setError('Please add panel line quantity.');
                return false;
            }
            if (!priceLine) {
                setError('Please add price per panel.');
                return false;
            }
        }
        if (poleLine || pricePoleLine) {
            if (!poleLine) {
                setError('Please add pole line quantity.');
                return false;
            }
            if (!pricePoleLine) {
                setError('Please add price per pole line.');
                return false;
            }
        }
        if (poleLine && pricePoleLine) {
            if (!(poleData[0]['poleQuantity'] && poleData[0])) {
                setError('Please pole details.');
                return false;
            }
        }
        let isErr = false;
        let isPole = false;
        poleData.map(key => {
            if (key['poleQuantity'] || key['poleSize']) {
                isPole = true;
                if (key['poleQuantity'] === '') {
                    setError('Please add pole quantity.');
                    isErr = true;
                }
                if (key['poleSize'] === '') {
                    setError('Please add pole size.');
                    isErr = true;
                }
            }
        });
        if (!isPole && !line && !priceLine) {
            setError('Please enter production data.');
            return false;
        }
        if (isErr) {
            return false;
        }
        if (!cementQty) {
            setError('Please add cement usage.');
            return false;
        }
        if (!comment) {
            setError('Please add a comment.');
            return false;
        }
        if (cementQty > cementStock) {
            setError(`Cement quantity ${cementQty} is not available in stock.`);
            return false;
        }
        setError('');
        return true;
    }

    const getPoleCount = () => {
        return poleData?.reduce((acc, item) => {
            const { poleQuantity } = item;
            if (poleQuantity !== "") {
                const quantity = parseFloat(poleQuantity);
                return acc + quantity;
            }
            return acc;
        }, 0)
    }

    const convertToPolesObject = (polesArray) => {
        return polesArray.reduce((acc, pole) => {
            const size = pole.poleSize;
            const quantity = parseInt(pole.poleQuantity, 10);

            if (acc[size]) {
                acc[size] += quantity;
            } else {
                acc[size] = quantity;
            }

            return acc;
        }, {});
    };

    const addProduction = async () => {
        if (validateForm()) {
            MixpanelTracker.sendAnalyticsEvent(EVENT_ACTIONS.ADD_PRODCUTION_CLICKED, userData)
            setLoading(true);
            if (data) {
                let payload = {
                    amount: (poleAmount + (parseFloat(line || 0) * parseFloat(priceLine || 0))).toString(),
                    date: date ? date : formatDate(new Date()),
                    time: (new Date()).toLocaleTimeString("en-US"),
                    clientId: `${userID}`, // Adjusted key based on client type
                    actionBy: userData.username,
                    panel: panelPieces ? panelPieces : 0,
                    line: line,
                    priceLine: priceLine,
                    poleData: poleData,
                    cementQty: cementQty,
                    poleLine: poleLine,
                    pricePoleLine: pricePoleLine,
                    pole: getPoleCount(),
                    poles: convertToPolesObject(poleData),
                    comment: comment
                }

                let url = `${API_CONFIG.PLANT}/${plantName}/${userID}/${cementQty}/${data.id}`;
                await makeApiRequest("put", url, authToken, refreshToken, setAuthToken, setRefreshToken, payload);
                setToastMessage("Production data updated successfully");
            } else {
                let payload = {
                    amount: (poleAmount + (parseFloat(line || 0) * parseFloat(priceLine || 0))).toString(),
                    date: date ? date : formatDate(new Date()),
                    time: (new Date()).toLocaleTimeString("en-US"),
                    clientId: `${userID}`, // Adjusted key based on client type
                    actionBy: userData.username,
                    panel: panelPieces ? panelPieces : 0,
                    line: line,
                    priceLine: priceLine ? priceLine : 0,
                    poleData: poleData,
                    cementQty: cementQty,
                    poleLine: poleLine,
                    pricePoleLine: pricePoleLine,
                    pole: getPoleCount(),
                    poles: getPoleCount() > 0 ? convertToPolesObject(poleData) : 0,
                    comment: comment
                }

                let url = `${API_CONFIG.PLANT}/${plantName}/${userID}/${cementQty}`;
                await makeApiRequest("post", url, authToken, refreshToken, setAuthToken, setRefreshToken, payload);
                setLoading(false);
                setToastMessage("Production added successfully");
            }

            navigation.goBack();
        }
    }

    const showDatePicker = () => {
        setDatePickerVisibility(true);
    };

    const hideDatePicker = () => {
        setDatePickerVisibility(false);
    };

    const handleDateConfirm = (date) => {
        setDate(formatDate(date));
        hideDatePicker();
    };

    return (
        <View style={{ backgroundColor: 'white', flex: 1 }}>
            {loading ? (
                <View style={styles.loaderContainer}>
                    <Loader size="large" />
                </View>
            ) : (
                <>
                    <FlatList
                        nestedScrollEnabled={true}
                        style={{ height: '100%', marginBottom: 85, backgroundColor: 'white' }}
                        data={[{ key: 'dummy' }]} // Provide a dummy item as FlatList requires data
                        renderItem={({ item }) => ( // Render the inner content within FlatList
                            <View style={styles.container}>
                                <View style={styles.rowContainer}>
                                    <Text style={styles.selectDateText}>Select Date:</Text>
                                    <View style={styles.dateContainer}>
                                        <TouchableOpacity onPress={showDatePicker}>
                                            <View style={styles.dateContainerView}>
                                                <FontAwesome5
                                                    name="calendar"
                                                    size={16}
                                                    color={Colors.primary}
                                                />
                                                <Text style={styles.dateText}> {date ? date : "Select date"}</Text>
                                                <FontAwesome5
                                                    name="caret-down"
                                                    size={16}
                                                    color={Colors.primary}
                                                />
                                                <Popup visible={isDatePickerVisible} onClose={() => setDatePickerVisibility(false)}>
                                                    <DateTimePicker
                                                        mode="single"
                                                        date={date || new Date()}
                                                        maxDate={today}
                                                        onChange={(param) => {
                                                            handleDateConfirm(new Date(param.date));
                                                            hideDatePicker();
                                                        }}
                                                        headerButtonStyle={{ color: 'green' }}
                                                    />
                                                </Popup>
                                            </View>
                                        </TouchableOpacity>
                                    </View>
                                </View>
                                <Text style={styles.productionTitle}>Panel Production:</Text>
                                <View style={styles.rowContainer}>
                                    <Text style={styles.inputText}>Panel Quantity (Line):</Text>
                                    <TextInput
                                        value={line}
                                        placeholder='Number of Lines'
                                        keyboardType="numeric"
                                        onChangeText={(text) => {
                                            if (text && panelSize) setPanelPieces(parseFloat(text) * parseFloat(panelSize).toString())
                                            setLine(text)
                                        }
                                        }
                                        style={styles.input}
                                    />
                                </View>
                                <View style={styles.rowContainer}>
                                    <Text style={styles.inputText}>Price/Line:</Text>
                                    <TextInput
                                        value={priceLine}
                                        placeholder='Price/Line'
                                        keyboardType="numeric"
                                        onChangeText={(text) => setPriceLine(text)}
                                        style={styles.input}
                                    />
                                </View>
                                <View style={styles.rowContainer}>
                                    <Text style={styles.inputText}>Number of Pieces:</Text>
                                    <TextInput
                                        value={panelPieces?.toString()}
                                        // onChangeText={(text) => setGstNo(text)}
                                        style={[styles.input, { color: '#7d7d7a' }]}
                                        editable={false}
                                    />
                                </View>
                                <View style={styles.rowContainer}>
                                    <Text style={styles.inputText}>Panel Amount:</Text>
                                    <TextInput
                                        value={(parseFloat(line || 0) * parseFloat(priceLine || 0)).toString()}
                                        style={[styles.input, { color: '#7d7d7a' }]}
                                        editable={false}
                                    />
                                </View>
                                <Text style={styles.productionTitle}>Pole Production:</Text>
                                <View style={styles.rowContainer}>
                                    <Text style={styles.inputText}>Line of Pole:</Text>
                                    <TextInput
                                        value={poleLine}
                                        placeholder='Pole line'
                                        keyboardType="numeric"
                                        onChangeText={(text) => {
                                            if (text && pricePoleLine) setPoleAmount(parseFloat(text) * parseFloat(pricePoleLine))
                                            else setPoleAmount(0)
                                            setPoleLine(text)
                                        }}
                                        style={styles.input}
                                    />
                                </View>
                                <View style={styles.rowContainer}>
                                    <Text style={styles.inputText}>Price per pole line:</Text>
                                    <TextInput
                                        value={pricePoleLine}
                                        placeholder='Price/Pole line'
                                        keyboardType="numeric"
                                        onChangeText={(text) => {
                                            if (text && poleLine) setPoleAmount(parseFloat(text) * parseFloat(poleLine))
                                            else setPoleAmount(0)
                                            setPricePoleLine(text)
                                        }}
                                        style={styles.input}
                                    />
                                </View>
                                {poleData.map((item, index) => (
                                    <View key={index} style={[styles.productContainer, { flexDirection: 'row', backgroundColor: '#F8F7F7', paddingBottom: 30 }, index !== poleData.length - 1 ? { borderBottomWidth: 1, borderBottomColor: 'black' } : { borderBottomWidth: 0 }]}>
                                        <View key={index} style={[styles.productContainer, { top: 10 }]}>
                                            <View style={styles.rowContainer}>
                                                <Text style={[styles.poleInputText, { width: '32%' }]}>Pole Quantity:</Text>
                                                <TextInput
                                                    placeholder='Pole Qty'
                                                    value={item.poleQuantity}
                                                    keyboardType="numeric"
                                                    onChangeText={(text) => updatePoleData(index, 'poleQuantity', text)}
                                                    style={[styles.inputRow, { width: '60%' }]}
                                                />
                                            </View>
                                            <View style={styles.rowContainer}>
                                                <Text style={[styles.poleInputText, { marginTop: 10 }]}>Pole Size:</Text>
                                                <MultiSelect
                                                    isOpen={open}
                                                    onClose={() => setOpen(false)}
                                                    options={item.isBolt ? POLE_SIZES_BOLT : POLE_SIZES}
                                                    selectedValues={[item.poleSize]}
                                                    onSelect={(selectedItems) => {
                                                        updatePoleData(index, 'poleSize', selectedItems[0])
                                                    }}
                                                    label={item.poleSize || "Select size"}
                                                    singleSelect={true}
                                                />
                                                <View style={{ top: 8 }}>
                                                    <CustomCheckbox
                                                        label={'With bolt'}
                                                        isChecked={item.isBolt}
                                                        onChange={() => {
                                                            updatePoleData(index, 'isBolt', !item.isBolt)
                                                            updatePoleData(index, 'poleSize', '')
                                                        }
                                                        } />
                                                </View>
                                            </View>
                                        </View>
                                        {index === 0 && ( // Render remove button for all rows except the first one
                                            <TouchableOpacity onPress={addPoleRow}>
                                                <Icon name="plus-circle" size={24} color="green" style={styles.removeButtonIcon} />
                                            </TouchableOpacity>
                                        )}
                                        {index !== 0 && ( // Render remove button for all rows except the first one
                                            <TouchableOpacity onPress={() => removePoleRow(index)}>
                                                <Icon name="minus-circle" size={24} color="red" style={styles.removeButtonIcon} />
                                            </TouchableOpacity>
                                        )}

                                    </View>

                                ))}
                                <View style={styles.rowContainer}>
                                    <Text style={styles.inputText}>Pole Amount:</Text>
                                    <TextInput
                                        value={poleAmount?.toString()}
                                        style={[styles.input, { color: '#7d7d7a' }]}
                                        editable={false}
                                    />
                                </View>
                                <View style={styles.rowContainer}>
                                    <Text style={styles.inputText}>Total Amount:</Text>
                                    <TextInput
                                        value={(poleAmount + (parseFloat(line || 0) * parseFloat(priceLine || 0))).toString()}
                                        style={[styles.input, { color: '#7d7d7a' }]}
                                        editable={false}
                                    />
                                </View>
                                <Text style={styles.productionTitle}>Cement Usage:</Text>
                                <View style={styles.rowContainer}>
                                    <Text style={styles.inputText}>Quantity (Bags):</Text>
                                    <TextInput
                                        value={cementQty}
                                        placeholder='Cement Qty'
                                        onChangeText={(text) => setCementQty(text)}
                                        style={styles.input}
                                        keyboardType="numeric"
                                    />
                                </View>
                                <View style={styles.rowContainer}>
                                    <Text style={styles.inputText}>Comment:</Text>
                                    <TextInput
                                        value={comment}
                                        placeholder='Add comment'
                                        onChangeText={(text) => setComment(text)}
                                        style={styles.input}
                                    />
                                </View>
                            </View>
                        )}
                    />
                    <View style={styles.container} >
                        {error ? <Text style={{ color: 'red', fontSize: 16, bottom: 80 }}>{error}</Text> : null}
                        <Button
                            btnLabel="ADD PRODUCTION"
                            buttonStyle={styles.BtnContainer}
                            textStyle={styles.btnText}
                            onPress={addProduction}
                            allowedRole={[APP_ROLE.OWNER, APP_ROLE.SUPERVISOR]} />
                    </View>
                </>)}
        </View>
    )
}
const styles = StyleSheet.create({
    loaderContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    container: {
        textAlign: 'center',
        alignItems: 'center',
    },
    selectDateText: {
        paddingLeft: 4,
        fontSize: 16,
        left: 10,
        fontWeight: '500',
        alignSelf: 'flex-start',
        textDecorationLine: 'underline'
    },
    rowContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '95%',
        top: 1,
        marginBottom: 2
    },
    inputText: {
        padding: 12,
        fontSize: 14,
        width: '30%',
        fontWeight: '500'
    },
    poleInputText: {
        // backgroundColor: 'black',
        fontSize: 12,
        width: '22%',
        fontWeight: '500'
    },
    productionTitle: {
        padding: 12,
        fontSize: 16,
        left: 10,
        fontWeight: '500',
        alignSelf: 'flex-start',
        textDecorationLine: 'underline'
    },
    input: {
        borderWidth: 2,
        borderColor: '#636363',
        padding: 2,
        paddingLeft: 5,
        marginRight: 5,
        fontSize: 14,
        borderRadius: 6,
        width: '58%',
    },
    productContainer: {
        flexDirection: 'column',
        alignItems: 'center',
        width: '90%',
    },
    inputRow: {
        borderWidth: 2,
        borderColor: '#636363',
        padding: 2,
        paddingLeft: 5,
        fontSize: 14,
        borderRadius: 6,
        width: '27%',
        marginLeft: 4,
        marginTop: 4
    },
    view: {
        flexDirection: 'row',
        alignItems: 'center',
        // height: 25,
        borderColor: '#636363',
        borderRadius: 6,
        margin: 12,
        padding: 12,
        width: '92%',
        right: 5,
        backgroundColor: "#E8E8E8"
    },
    BtnContainer: {
        paddingVertical: 20,
        paddingHorizontal: 40,
        width: '90%',
        bottom: 10,
        position: 'absolute',
        backgroundColor: Colors.secondary,
    },
    btnText: {
        color: 'white',
    },
    text: {
        marginLeft: 10,
        marginRight: 10
    },
    removeButtonIcon: {
        marginLeft: 12, // Adding some space between the input fields and the icon
    },
    dateContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        height: 30,
        borderColor: '#636363',
        borderRadius: 6,
        paddingHorizontal: 8,
        width: '50%',
        marginRight: 20,
        backgroundColor: "lightgray"
    },
    dateContainerView: {
        flexDirection: 'row',
    },
    dateText: {
        marginLeft: 5,
        marginRight: 5
    }
})
export default AddProductionScreen;
